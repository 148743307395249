.container {
  position: relative;
  min-height: 300px;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  text-shadow: 1px 1px 10px rgba(var(--theme-bg-1-rgb), 0.4);
  opacity: 0.8;
  color: white;
  background-size: cover;
  background-position: center;
  transition: opacity 0.1s ease-in-out;
}

.container::after {
  width: 100%;
  position: absolute;
  height: 100%;
  background: linear-gradient(-135deg, transparent, #0000004d);
  content: '';
  z-index: -1;
  bottom: 0;
  left: 0;
}

.edit-container {
  position: absolute;
  background-color: rgba(var(--theme-bg-1-rgb), 0.8);
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.7px);
}

.edit-icon,
.delete-icon {
  opacity: 0.7;
  cursor: pointer;
}

button:hover > .edit-icon,
button:focus > .edit-icon,
button:hover > .delete-icon,
button:focus > .delete-icon {
  opacity: 1;
}

.edit-container button:focus > .delete-icon,
.delete-icon:hover {
  color: var(--theme-warning-1);
}

.add-card {
  display: grid;
  place-items: center;
  width: 100%;
  justify-content: unset;
  border: 2px dashed var(--theme-highlight-1);
  color: var(--theme-highlight-1);
  background: transparent;
  transition: background-color 0.5s var(--ease-1);
  cursor: pointer;
}

.add-card:hover,
.add-card:focus {
  outline: none;
  border-style: solid;
  background-color: rgba(var(--theme-highlight-1-rgb), 0.3);
}
