.title {
  font-size: 1rem;
  margin-top: 0px;
}

.modal {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  width: 80%;
  padding: 10px;
}

@media (max-width: 600px) {
  .modal {
    width: 100%;
  }
}

.editor {
  width: 100%;
  background: var(--theme-bg-1);
  color: var(--theme-color-1);
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 10px;
}

.editor::-webkit-scrollbar {
  background-color: var(--theme-bg-2);
  width: 3px;
}

.editor::-webkit-scrollbar-thumb {
  background-color: var(--theme-highlight-1);
  border-radius: 10px;
  outline: none;
}

.modal-button-container {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  align-items: center;
}

.icon {
  cursor: pointer;
  opacity: 0.3;
}

a:hover > .icon,
a:focus > .icon,
button:hover > .icon,
button:focus > .icon {
  opacity: 1;
}

.config-actions-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: var(--theme-color-1);
}

.error {
  padding: 5px 10px;
  border: 1px solid red;
  background-color: #ff00000f;
  margin: 10px 0;
  white-space: pre;
  font-family: monospace;
}

.upload {
  background: var(--theme-bg-3);
  border: none;
  padding: 7px 20px;
  color: var(--theme-color-1);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s var(--ease-1);
}

.upload:focus,
.upload:hover {
  outline: none;
  background-color: transparent;
  box-shadow: 0 0 0 2px var(--theme-highlight-1);
  color: var(--theme-highlight-1);
}

.spacer {
  flex-grow: 1;
}
