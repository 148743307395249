.upload {
  position: relative;
  display: grid;
  place-items: center;
}

.dropzone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 78%);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  border: 2px dashed var(--theme-highlight-1);
  color: white;
  grid-gap: 30px;
  font-size: 2rem;
  font-weight: bold;
  backdrop-filter: blur(5px);
}

@media (max-width: 600px) {
  .dropzone {
    display: none;
  }
}

.highlight {
  background-color: rgba(var(--theme-highlight-1-rgb), 0.3);
  border-style: solid;
}

.dropzone svg {
  color: var(--theme-highlight-1);
}
