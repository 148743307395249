.button {
  background: transparent;
  color: inherit;
  border: none;
}

.a:focus,
.button:focus {
  outline: none;
  color: var(--theme-highlight-1);
}
